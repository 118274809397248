import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout/layout"
import {container, leftBackgroundImage, rightBackgroundImage, message, info, buttonOk, messageContainer} from "../assets/pageStyles/thankYou.module.scss";
import SuccessImg from "../images/signup/success.svg";
import BackgroundLeftGradient from "../images/signup/background-left-gradient.svg";
import BackgroundRightGradient from "../images/signup/background-right-gradient.svg";
import {graphql, navigate} from 'gatsby';
import {useTranslation} from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ThankYouPage = (props) => {
    const {t}=useTranslation();

    return (
        <Layout location={props?.location?.pathname}>
            <div className={container}>

                <img
                    className={leftBackgroundImage}
                    src={BackgroundLeftGradient}
                    alt="background"
                />
                <img
                    className={rightBackgroundImage}
                    src={BackgroundRightGradient}
                    alt="background"
                />
                <div className={messageContainer}>
                    <img src={SuccessImg} alt="success message" />
                    <p className={message}>{t('thankYouPage.textOne')}</p>
                    <p className={info}>{t('thankYouPage.textTwo')}</p>
                    <button
                        className={buttonOk}
                        onClick={(e) => {
                            navigate('/')
                        }}
                    >{t('thankYouPage.okay')}</button>
                </div>
            </div>
        </Layout>
    )
}

export default ThankYouPage

const title = "Thank you for registering";
const description = 'Create, report, and store documents while uncovering hidden pain points in your workflow and optimizing every step of your company\'s work funnel.'

export const Head = () => <Seo description={description} title={title} />

